<div class="reports-page">
    <div class="container">
        <h2 class="title">Հաշվետվություններ</h2>
        <p>Այստեղ ներկայացված են «ՖԼԱՅ-ՆԵթ» ընկերության ֆինանսական հաշվետվությունները:</p>
        <div class="reports-list">
            <div class="list-item" *ngFor="let item of reports.reverse()">
                <a [href]="item.file" target="_blank">
                    <div class="title">
                       {{item.title}}
                    </div>
                    <i class="fa fa-file-pdf"></i>
                </a>
            </div>
        </div>
    </div>
</div>