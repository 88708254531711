import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  reports:any[] = [
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2021թ., I եռամսյակ)',file:'assets/files/2021-1.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2021թ., II եռամսյակ)',file:'assets/files/2021-2.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2021թ., III եռամսյակ)',file:'assets/files/2021-3.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2021թ., IV եռամսյակ)',file:'assets/files/2021-4.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2022թ., I եռամսյակ)',file:'assets/files/2022-1.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2022թ., II եռամսյակ)',file:'assets/files/2022-2.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2022թ., III եռամսյակ)',file:'assets/files/2022-3.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2022թ., IV եռամսյակ)',file:'assets/files/2022-4.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2023թ., I եռամսյակ)',file:'assets/files/2023-1.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2023թ., II եռամսյակ)',file:'assets/files/2023-2.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2023թ., III եռամսյակ)',file:'assets/files/2023-3.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2023թ., IV եռամսյակ)',file:'assets/files/2023-4.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2024թ., I եռամսյակ)',file:'assets/files/2024-1.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2024թ., II եռամսյակ)',file:'assets/files/2024-2.pdf'},
    {title:'«ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ (2024թ., III եռամսյակ)',file:'assets/files/2024-3.pdf'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
