<section class="section_1">
    <carousel [objectFit]="'cover'" [cellWidth]="'100%'" [height]="590" [arrows]="false" [dots]="true" [autoplay]="true" [loop]="true">
        <div class="carousel-cell internet">
            <div class="row full-width">
                <div class="offset-md-1 col-md-6 col-sm-12 align-self-center">
                    <div data-aos="fade-right">
                        <h2>Գերարագ Ինտերնետ Կապ</h2>
                        <p>"Fly Net"-ը ավելին է քան ուղղակի օպերատոր։<br> Ստացեք գերարագ ինտերնետ կապ մինչև 45 ՄԲ/վ</p>
                        <!-- <a routerLink="/services"><button mat-raised-button color="primary" class="mt-3"><span class="mx-2">Ծառայություններ</span> <i class="far fa-arrow-alt-circle-right "></i></button></a> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-cell tv">
            <div class="row full-width">
                <div class="offset-md-1 col-md-6 col-sm-12 align-self-center">
                    <div data-aos="fade-right">
                        <h2>Հեռուստատեսություն</h2>
                        <p>100 թվային SD, HD հեռուստաալիքներ։<br>Մինչև 3 DVBT2 թվային հեռուստացույցների միացում</p>
                        <!-- <a routerLink="/services"><button mat-raised-button color="primary" class="mt-3"><span class="mx-2">Ծառայություններ</span> <i class="far fa-arrow-alt-circle-right "></i></button></a> -->
                    </div>
                </div>
            </div>
        </div>
    </carousel>
</section>
<section class="section_2">
    <div class="container">
        <div class="row">
            <div class="col-md-4" data-aos="fade-up">
                <div class="services_item">
                    <div class="icon">
                        <img src="assets/images/wifi.svg">
                    </div>
                    <div class="name">
                        <h3>Ինտերնետ</h3>
                        <p>Օպտիկամանրաթելային մալուխ մինչև տուն:</p>
                        <h3>Մինչև 20 Մբ/վ</h3>
                    </div>
                    <div class="description">
                        <ul>
                            <li>Անվճար միացում</li>
                            <li>Անվճար WIFI սարքի տրամադրում</li>
                            <li>Անվճար տեխնիկական սպասարկում</li>
                        </ul>
                    </div>
                    <div class="price">
                        <h2>6000դր․</h2>
                    </div>
                </div>
            </div>

            <div class="col-md-4" data-aos="fade-down">
                <div class="services_item tv">
                    <div class="icon">
                        <img src="assets/images/tv.svg">
                    </div>
                    <div class="name">
                        <h3>Ինտերնետ + FLY TV փաթեթ</h3>
                        <h3>Մինչև 45 ՄԲ/վ</h3>
                        <h3>100 թվային SD, HD հեռուստաալիքներ</h3>
                    </div>
                    <div class="description">
                        <ul>
                            <li>Անվճար միացում</li>
                            <li>Անվճար WIFI սարքի տրամադրում</li>
                            <li>Անվճար տեխնիկական սպասարկում</li>
                        </ul>
                    </div>
                    <div class="price">
                        <h2>7000դր․</h2>
                    </div>
                </div>
            </div>

            <div class="col-md-4" data-aos="fade-up">
                <div class="services_item">
                    <div class="icon">
                        <img src="assets/images/wifi.svg">
                    </div>
                    <div class="name">
                        <h3>Ինտերնետ</h3>
                        <p>Օպտիկամանրաթելային մալուխ մինչև տուն:</p>
                        <h3>Մինչև 35 Մբ/վ</h3>
                    </div>
                    <div class="description">
                        <ul>
                            <li>Անվճար միացում</li>
                            <li>Անվճար WIFI սարքի տրամադրում</li>
                            <li>Անվճար տեխնիկական սպասարկում</li>
                        </ul>
                    </div>
                    <div class="price">
                        <h2>8000դր․</h2>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section class="section_3">
    <div class="mask"></div>
    <div class="container">
        <h2>"Fly Net" Գերարագ ինտերնետ մատչելի գնով</h2>
    </div>
</section>
<section class="section_4">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <h2 class="mb-2">Ինչպե՞ս օգտվել "Fly Net"-ի ինտերնետից և հեռուստատեսությունից</h2>
                <div class="phones" data-aos="fade-right">
                    <a href="tel:">(+374 12) 70 70 70 , </a>
                    <a href="tel:">(+374 99) 100 878</a>
                </div>
                <ul>
                    <li>Զանգահարեք և մեր կոնտակտային մասնագետը կպատասխանի Ձեր բոլոր հարցերին</li>
                    <li>Մեր մասնագետների կողմից կատարվում է լարանցում (մոնտաժային աշխատանքներ)</li>
                    <li>Կատարվում են բոլոր միացումները և պայմանագրի ձևակերպումը</li>
                    <li>Ողջ գործընթացը տևում է 1-ից 3 աշխատանքային օր</li>
                </ul>
                <!-- <mat-accordion class="example-headers-align" multi>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>

                        </mat-expansion-panel-header>
                    </mat-expansion-panel>
                </mat-accordion> -->
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="frame">
                    <img src="assets/images/switch.jpg">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section_5">
    <div id="map" style="width: 100%;height: 500px;"></div>
</section>
<section class="section_6">
    <div class="container">
        <carousel [objectFit]="'none'" [images]="partners" [arrows]="true" [dots]="false"></carousel>
    </div>
</section>