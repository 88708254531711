import { Component, OnInit } from '@angular/core';

declare var ymaps:any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public map :any;
  constructor() { }

  partners:Array<any>=[
    {path:'/assets/images/logos/brand.png'},
    {path:'/assets/images/logos/network.png'},
    {path:'/assets/images/logos/vivacell.svg'},
    {path:'/assets/images/logos/rostelecom.png'},
    {path:'/assets/images/logos/easy.svg'},
  ]

  ngOnInit(): void {
    

    ymaps.ready().then(() => {
      this.map = new ymaps.Map('map', {
        center: [50.450100, 30.523400],
        zoom: 12
      });
      var polygon = new ymaps.Polygon([
        // Координаты внешнего контура.
      [[40.241911, 44.567189], [40.261105, 44.594225], [40.260581, 44.616112], [40.251934, 44.640574],[40.237587, 44.640231],[40.241911, 44.567189]],
  ], {
      hintContent: "ՀԱսանելիության քարտեզ"
  }, {
      fillColor: '#6699ff',
      // Делаем полигон прозрачным для событий карты.
      interactivityModel: 'default#transparent',
      strokeWidth: 8,
      opacity: 0.5
  });
  this.map.geoObjects.add(polygon);
  this.map.setBounds(polygon.geometry.getBounds());
    });
  }

}
