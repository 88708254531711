<div class="banner">
    <div class="mask"></div>
    <h3>Կապ</h3>
</div>
<div class="contacts">
    <div class="container">
        <div class="row">
            <div class="col-sm-4" data-aos="fade-up-right">
                <div class="item">
                    <div class="icon">
                        <img src="/assets/images/location-icon.png">
                    </div>
                    <h3 class="title">Հասցե</h3>
                    <p>Հ․ Վերդյան փ․, 3շ․ 2բն․,<br>գ․ Գետարգել, Կոտայքի մարզ, ՀՀ</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="item">
                    <div class="icon">
                        <img src="/assets/images/phone-icon.png">
                    </div>
                    <h3 class="title my-2">Հեռախոսահամար</h3>
                    <p>(+374 99)100878 , (+374 12) 707070</p>
                </div>
            </div>
            <div class="col-sm-4" data-aos="fade-up-left">
                <div class="item">
                    <div class="icon">
                        <img src="/assets/images/email-icon.png">
                    </div>
                    <h3 class="title">Էլ․ Հասցե</h3>
                    <p>flynet@internet.ru</p>
                </div>
            </div>
        </div>
        <div class="row contact-form">
            <div class="col-md-6 col-sm-12">
                <h3>Կապ մեզ հետ</h3>
                <form>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Էլ․ Հասցե</mat-label>
                        <input matInput placeholder="Էլ․ Հասցե" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Անուն Ազգանուն</mat-label>
                        <input matInput placeholder="Անուն Ազգանուն" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Հաղորդագրություն</mat-label>
                        <textarea matInput rows="5" placeholder="Հաղորդագրություն" required></textarea>
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="m1-3">Ուղարկել <i class="fab fa-telegram-plane ml-2"></i></button>
                </form>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="map">
                    <div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Яндекс.Карты</a><a href="https://yandex.ru/maps/105793/kotayk/house/YE0Ycw5lS0UHQFpqfX51dXVnZw==/?ll=44.595200%2C40.244943&utm_medium=mapframe&utm_source=maps&z=17.05"
                            style="color:#eee;font-size:12px;position:absolute;top:14px;">Улица Арутюна Вердяна, 3 — Яндекс.Карты</a><iframe src="https://yandex.ru/map-widget/v1/-/CCU4UOq60D" width="100%" height="400" frameborder="1" allowfullscreen="true"
                            style="position:relative;"></iframe></div>
                </div>
            </div>
        </div>
    </div>
</div>