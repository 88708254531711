<div class="banner">
    <div class="mask"></div>
    <h3>Մեր Մասին</h3>
</div>
<div class="about-us">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-sm-12">
                <div class="frame">
                    <img src="/assets/images/workplace.jpg">
                </div>
            </div>
            <div class="col-md-7 col-sm-12" data-aos="fade-left">
                <h3>Ընկերության պատմությունը</h3>
                <p> «ՖԼԱՅՆԵՏ» ՍՊԸ-ն հիմնադրվել է 2018թ․–ին:
                    <br>Մեր ընկերությունը բաժանորդներին տրամադրում է վերջին սերնդի FTTH (fiber to the home) օպտիկական մալուխ մինչև տուն՝ GPON տեխնոլոգիայով, ինչպես նաև մատակարարում է հեռուստատեսության ծառայություններ: Ընկերությունն առաջարկում է բարձրակարգ
                    ծառայություններ՝ տրամադրելով օպտիկամանրաթելային մալուխներով կառուցված վերջին սերնդի սարքավորումներով հագեցած գերարագ ցանց, որը հնարավորություն է տալիս լիարժեք և բարձրակարգ սպասարկում իրականացնել ինչպես անհատական, այնպես էլ կորպորատիվ
                    բաժանորդների համար: Մեզ մոտ գործում են բիզնես համագործակցության ճկուն, շահավետ փաթեթներ, որոնք հարմարեցվում են պատվիրատուների պահանջներին:
                </p>
            </div>
        </div>
    </div>
</div>