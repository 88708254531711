import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fly-net';
  constructor(public router:Router){}

  ngOnInit(){
    AOS.init({
      duration: 500,
      easing: 'ease-in-sine',
      delay: 100,
    });
  }
}
