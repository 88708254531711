<mat-toolbar class="navbar">
    <mat-toolbar-row>
        <div class="container-fluid ">
            <div class="logo mr-3">
                <a routerLink="/" class="full-width"><img src="assets/images/logo.svg" /></a>
            </div>
            <div class="menu">
                <a routerLink="home"><button mat-menu-item routerLinkActive="active"><span>Գլխավոր</span></button></a>
                <a routerLink="about-us"><button mat-menu-item routerLinkActive="active"><span>Մեր Մասին</span></button></a>
                <a routerLink="reports"><button mat-menu-item routerLinkActive="active"><span>Հաշվետվություն</span></button></a>
                <a routerLink="contacts"><button mat-menu-item routerLinkActive="active"><span>Կապ</span></button></a>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>